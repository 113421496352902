import config from "@/configs/timemanagement";
import storeAuth from "@/helpers/store/storeAuthHelper"
import { apiGetListLoop } from "@/helpers/api_common"
import { isiOS } from "@/helpers/common/common_util"
import { buildApiUrl } from "@/helpers/api_common"

import api_notification from "./api/notification.js";
import api_task from "./api/task.js";
import api_template from "./api/template.js";
import api_pattern from "./api/pattern.js";
import api_checksheet from "./api/checksheet.js";
import api_emergency from "./api/emergency.js";
import api_personal_task from "./api/personal_task.js";
import api_manual from "./api/manual.js";
import api_routine from "./api/routine.js";
import api_routine_personal_task from "./api/routine personal_task.js";
import api_tags from "./api/tags.js";
import api_translate from "./api/translate.js";
import api_ranking from "./api/ranking.js";
import api_dashboard from "./api/dashboard.js";

// お知らせ
export async function apiNotificationGetBarList() {
  return api_notification.getListForBar(config.api, storeAuth.storeGetSelectedStaffId());
}

// タスク
export async function apiTimemngTaskGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_task.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export async function apiTimemngTaskSearch(conditions) {
  return api_task.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngTaskCreate(task, attached_files) {
  return api_task.create(config.api, storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export function apiTimemngTaskUpdate(task) {
  return api_task.update(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export async function apiTimemngTaskGetTagList(page) {
  return await apiGetListLoop((_page) => {
    return api_task.getTagList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngTaskCreateTag(tag) {
  return api_task.createTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngTaskAddTag(tag) {
  return api_task.addTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngTaskRemoveTag(tag) {
  return api_task.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export async function apiTimemngTaskGetOptionList(page) {
  return await apiGetListLoop((_page) => {
    return api_task.getOptionList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngTaskCreateOption(option) {
  return api_task.createOption(config.api, storeAuth.storeGetSelectedStaffId(), option);
}
export function apiTimemngTaskAddOption(option) {
  return api_task.addOption(config.api, storeAuth.storeGetSelectedStaffId(), option);
}
export function apiTimemngTaskRemoveOption(option) {
  return api_task.removeOption(config.api, storeAuth.storeGetSelectedStaffId(), option);
}
export function apiTimemngTaskAddAttachment(task, attached_files) {
  return api_task.addAttachment(config.api, storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export function apiTimemngTaskRemoveAttachment(task) {
  return api_task.removeAttachment(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTaskPreviewFile(task_id, file_id, original = 1) {
  return api_task.previewFile(config.api, storeAuth.storeGetSelectedStaffId(true), task_id, file_id, original);
}
export function apiTimemngTaskSaveAttachmentCaption(task) {
  return api_task.saveAttachmentCaption(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTaskAddManual(task) {
  return api_task.addManual(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTaskRemoveManual(task) {
  return api_task.removeManual(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTaskInUseSchedules(task_id) {
  return api_task.getTaskInUse(config.api, storeAuth.storeGetSelectedStaffId(), task_id);
}
export function apiTimemngTaskExport(shop_id, export_format) {
  return api_task.exportTask(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, export_format);
}

// サブタスク
export async function apiTimemngTaskSubGetList(task_id, page) {
  return await apiGetListLoop((_page) => {
    return api_task.getListSub(config.api, storeAuth.storeGetSelectedStaffId(), task_id, _page);
  }, page, 10)
}
export function apiTimemngTaskSubCreate(task_sub, attached_files) {
  return api_task.createSub(config.api, storeAuth.storeGetSelectedStaffId(), task_sub, attached_files);
}
export function apiTimemngTaskSubUpdate(task_sub) {
  return api_task.updateSub(config.api, storeAuth.storeGetSelectedStaffId(), task_sub);
}
export function apiTimemngTaskSubAdd(task_sub) {
  return api_task.addSub(config.api, storeAuth.storeGetSelectedStaffId(), task_sub);
}
export function apiTimemngTaskSubRemove(task_sub) {
  return api_task.removeSub(config.api, storeAuth.storeGetSelectedStaffId(), task_sub);
}
export async function apiTimemngTaskSubGetOptionList(page) {
  return await apiGetListLoop((_page) => {
    return api_task.getOptionListSub(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngTaskSubAddOption(option) {
  return api_task.addOptionSub(config.api, storeAuth.storeGetSelectedStaffId(), option);
}
export function apiTimemngTaskSubRemoveOption(option) {
  return api_task.removeOptionSub(config.api, storeAuth.storeGetSelectedStaffId(), option);
}
export function apiTimemngTaskSubAddAttachment(task, attached_files) {
  return api_task.addAttachmentSub(config.api, storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export function apiTimemngTaskSubRemoveAttachment(task) {
  return api_task.removeAttachmentSub(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTaskSubPreviewFile(task_sub_id, file_id, original = 1) {
  return api_task.previewFileSub(config.api, storeAuth.storeGetSelectedStaffId(true), task_sub_id, file_id, original);
}
export function apiTimemngTaskSubSaveAttachmentCaption(tasksub) {
  return api_task.saveAttachmentCaptionSub(config.api, storeAuth.storeGetSelectedStaffId(), tasksub);
}
export function apiTimemngTaskSubCreateDish(dish) {
  return api_task.createDishSub(config.api, storeAuth.storeGetSelectedStaffId(), dish);
}
export function apiTimemngTaskSubUpdateDish(dish) {
  return api_task.updateDishSub(config.api, storeAuth.storeGetSelectedStaffId(), dish);
}
// タスク（My店舗）
export async function apiTimemngMyShopTaskSearch(conditions) {
  return api_task.searchMyShop(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export async function apiTimemngTemplateListMyShopSearch(conditions) {
  return api_template.searchMyShop(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetKeyAsSelectedShop(), conditions);
}
export function apiTimemngMyShopTaskCreate(task, attached_files) {
  return api_task.createMyShop(config.api, storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export function apiTimemngMyShopTaskUpdate(task) {
  return api_task.update(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
// タスク（日報）
export function apiTimemngTaskDailyReportList(daily_report_ids) {
  return api_task.listDailyReport(config.api, storeAuth.storeGetSelectedStaffId(), daily_report_ids);
}
export function apiTimemngTaskDailyReportCreate(report) {
  return api_task.createDailyReport(config.api, storeAuth.storeGetSelectedStaffId(), report);
}
export function apiTimemngTaskDailyReportUpdate(report) {
  return api_task.updateDailyReport(config.api, storeAuth.storeGetSelectedStaffId(), report);
}
export function apiTimemngTaskDailyReportItemsSave(report_items) {
  return api_task.saveDailyReportItems(config.api, storeAuth.storeGetSelectedStaffId(), report_items);
}

// タスクグループ
export async function apiTimemngTemplateGetList(page, template_exist) {
  return await apiGetListLoop((_page) => {
    return api_template.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page, template_exist);
  }, page, 10)
}
export async function apiTimemngTemplateTaskSearch(conditions) {
  return api_template.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngTemplateCreate(template) {
  return api_template.create(config.api, storeAuth.storeGetSelectedStaffId(), template);
}
export function apiTimemngTemplateUpdate(template) {
  return api_template.update(config.api, storeAuth.storeGetSelectedStaffId(), template);
}
export function apiTimemngTemplateAddTask(task) {
  return api_template.addTask(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngTemplateRemoveTask(task_template_id, task_id) {
  return api_template.removeTask(config.api, storeAuth.storeGetSelectedStaffId(), task_template_id, task_id);
}
// タスクグループ（My店舗用）
export async function apiTimemngTemplateGetListMyShop(shop_id, page, template_exist) {
  return await apiGetListLoop((_page) => {
    return api_template.getListMyShop(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page, template_exist);
  }, page, 10)
}
export function apiTimemngTemplateCreateMyShop(template) {
  return api_template.createMyShop(config.api, storeAuth.storeGetSelectedStaffId(), template);
}


// 業務スケジュール
export async function apiTimemngPatternGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_pattern.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export async function apiTimemngPatternSearch(conditions) {
  return api_pattern.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngPatternCreate(pattern) {
  return api_pattern.create(config.api, storeAuth.storeGetSelectedStaffId(), pattern);
}
export function apiTimemngPatternUpdate(pattern) {
  return api_pattern.update(config.api, storeAuth.storeGetSelectedStaffId(), pattern);
}
export async function apiTimemngPatternGetApplyValues(pattern_id, page = 1) {
  return api_pattern.getApplyValues(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, page);
}
export function apiTimemngPatternAddApplyValue(pattern_id, apply_value) {
  return api_pattern.addApplyValue(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, apply_value);
}
export function apiTimemngPatternRemoveApplyValue(pattern_id, apply_value) {
  return api_pattern.removeApplyValue(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, apply_value);
}
export function apiTimemngPatternAddTemplate(pattern_id, task_template_id) {
  return api_pattern.addTemplate(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, task_template_id);
}
export function apiTimemngPatternRemoveTemplate(pattern_id, task_template_id) {
  return api_pattern.removeTemplate(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, task_template_id);
}
export function apiTimemngPatternAddShop(pattern_id, shop_id) {
  return api_pattern.addShop(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, shop_id);
}
export function apiTimemngPatternRemoveShop(pattern_id, shop_id) {
  return api_pattern.removeShop(config.api, storeAuth.storeGetSelectedStaffId(), pattern_id, shop_id);
}
export async function apiTimemngPatternGetExcludeTemplateList(shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_pattern.excludeTemplateList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page);
  }, page, 10)
}
export async function apiTimemngPatternGetExcludeTaskList(shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_pattern.excludeTaskList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page);
  }, page, 10)
}
export function apiTimemngPatternGetExcludeTemplateLogs(exid) {
  return api_pattern.excludeTemplateLogs(config.api, storeAuth.storeGetSelectedStaffId(), exid);
}
export function apiTimemngPatternGetExcludeTaskLogs(exid) {
  return api_pattern.excludeTaskLogs(config.api, storeAuth.storeGetSelectedStaffId(), exid);
}
// 業務スケジュール（My店舗）
export async function apiTimemngPatternGetListMyShop(shop_id, page, draft = 0) {
  return await apiGetListLoop((_page) => {
    return api_pattern.getListMyShop(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page, draft);
  }, page, 10)
}
export async function apiTimemngPatternSearchMyShop(conditions) {
  return api_pattern.searchMyShop(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngPatternCreateMyShop(pattern) {
  return api_pattern.createMyShop(config.api, storeAuth.storeGetSelectedStaffId(), pattern);
}
export function apiTimemngPatternUpdateMyShop(pattern) {
  return api_pattern.update(config.api, storeAuth.storeGetSelectedStaffId(), pattern);
}
export function apiTimemngPatternExcludeTemplate(exclude) {
  return api_pattern.excludeTemplate(config.api, storeAuth.storeGetSelectedStaffId(), exclude);
}
export function apiTimemngPatternExcludeTask(exclude) {
  return api_pattern.excludeTask(config.api, storeAuth.storeGetSelectedStaffId(), exclude);
}
// 業務スケジュール（店舗カスタム）
export async function apiTimemngPatternCustomTaskHistory(approved_status, shop_id, pattern_ids) {
  return api_pattern.customTaskHistory(config.api, storeAuth.storeGetSelectedStaffId(), approved_status, shop_id, pattern_ids);
}
export function apiTimemngPatternCustomTaskCreate(customs) {
  return api_pattern.customTaskCreate(config.api, storeAuth.storeGetSelectedStaffId(), customs);
}
export function apiTimemngPatternCustomTaskUpdate(customs) {
  return api_pattern.customTaskUpdate(config.api, storeAuth.storeGetSelectedStaffId(), customs);
}
export function apiTimemngPatternCustomTaskApprove(approvals) {
  return api_pattern.customTaskApprove(config.api, storeAuth.storeGetSelectedStaffId(), approvals);
}
export function apiTimemngPatternCustomTaskRemand(remands) {
  return api_pattern.customTaskRemand(config.api, storeAuth.storeGetSelectedStaffId(), remands);
}
export function apiTimemngPatternCustomReset(reset) {
  return api_pattern.customReset(config.api, storeAuth.storeGetSelectedStaffId(), reset);
}
// 新デザイン形式（テンプレート無しバージョン）
export function apiTimemngPatternCreateNoTemplateVer(pattern, template) {
  return api_pattern.createNoTemplateVer(config.api, storeAuth.storeGetSelectedStaffId(), pattern, template);
}
export function apiTimemngPatternCreateNoTemplateVerMyShop(pattern, template) {
  return api_pattern.createNoTemplateVerMyShop(config.api, storeAuth.storeGetSelectedStaffId(), pattern, template);
}

// チェックシート
export async function apiTimemngChecksheetGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_checksheet.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export async function apiTimemngChecksheetGetListAll(page) {
  return await apiGetListLoop((_page) => {
    return api_checksheet.getListAll(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngChecksheetGetDetail(checksheet_id) {
  return api_checksheet.getDetail(config.api, storeAuth.storeGetSelectedStaffId(), checksheet_id);
}
export function apiTimemngChecksheetCreate(checksheet, details) {
  return api_checksheet.create(config.api, storeAuth.storeGetSelectedStaffId(), checksheet, details);
}
export function apiTimemngChecksheetUpdate(checksheet, details) {
  return api_checksheet.update(config.api, storeAuth.storeGetSelectedStaffId(), checksheet, details);
}
export function apiTimemngChecksheetAddBrand(checksheet_id, brand_id) {
  return api_checksheet.addBrand(config.api, storeAuth.storeGetSelectedStaffId(), checksheet_id, brand_id);
}
export function apiTimemngChecksheetRemoveBrand(checksheet_id, brand_id) {
  return api_checksheet.removeBrand(config.api, storeAuth.storeGetSelectedStaffId(), checksheet_id, brand_id);
}
export async function apiTimemngChecksheetGetTagList(page) {
  return await apiGetListLoop((_page) => {
    return api_checksheet.getTagList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngChecksheetCreateTag(tag) {
  return api_checksheet.createTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngChecksheetAddTag(tag) {
  return api_checksheet.addTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngChecksheetRemoveTag(tag) {
  return api_checksheet.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngChecksheetGetLogs(checksheet_id) {
  return api_checksheet.getLogs(config.api, storeAuth.storeGetSelectedStaffId(), checksheet_id);
}
export function apiTimemngChecksheetAddDetailAttachment(checksheet, attached_files) {
  return api_checksheet.addDetailAttachment(config.api, storeAuth.storeGetSelectedStaffId(), checksheet, attached_files);
}
export function apiTimemngChecksheetRemoveDetailAttachment(checksheet) {
  return api_checksheet.removeDetailAttachment(config.api, storeAuth.storeGetSelectedStaffId(), checksheet);
}
export function apiTimemngChecksheetDetailPreviewFile(checksheet_detail_id, file_id, original) {
  return api_checksheet.previewDetailFile(config.api, storeAuth.storeGetSelectedStaffId(), checksheet_detail_id, file_id, original);
}

// チェックシート(My店舗)
export async function apiTimemngChecksheetGetListMyShop(shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_checksheet.getListMyShop(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page);
  }, page, 10)
}
export function apiTimemngChecksheetCreateMyShop(checksheet, details) {
  return api_checksheet.createMyShop(config.api, storeAuth.storeGetSelectedStaffId(), checksheet, details);
}

// 臨時タスク
export async function apiTimemngEmergencyList(page) {
  return await apiGetListLoop((_page) => {
    return api_emergency.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngEmergencySearch(conditions) {
  return api_emergency.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngEmergencyCreate(emergency, attached_files) {
  return api_emergency.create(config.api, storeAuth.storeGetSelectedStaffId(), emergency, attached_files);
}
export function apiTimemngEmergencyUpdate(emergency) {
  return api_emergency.update(config.api, storeAuth.storeGetSelectedStaffId(), emergency);
}
export function apiTimemngEmergencyAddShop(emergency_id, target_id, with_chatmessage) {
  return api_emergency.addTarget(config.api, storeAuth.storeGetSelectedStaffId(), emergency_id, target_id, with_chatmessage);
}
export function apiTimemngEmergencyRemoveShop(emergency_id, target_id) {
  return api_emergency.removeTarget(config.api, storeAuth.storeGetSelectedStaffId(), emergency_id, target_id);
}
export function apiTimemngEmergencyAddAttachment(emergency, attached_files) {
  return api_emergency.addAttachment(config.api, storeAuth.storeGetSelectedStaffId(), emergency, attached_files);
}
export function apiTimemngEmergencyRemoveAttachment(emergency) {
  return api_emergency.removeAttachment(config.api, storeAuth.storeGetSelectedStaffId(), emergency);
}
export function apiTimemngEmergencySaveAttachmentCaption(emergency) {
  return api_emergency.saveAttachmentCaption(config.api, storeAuth.storeGetSelectedStaffId(), emergency);
}
export async function apiTimemngEmergencyGetTagList(page) {
  return await apiGetListLoop((_page) => {
    return api_emergency.getTagList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiTimemngEmergencyCreateTag(tag) {
  return api_emergency.createTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngEmergencyAddTag(tag) {
  return api_emergency.addTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngEmergencyRemoveTag(tag) {
  return api_emergency.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngEmergencyPreviewFile(emergency_id, file_id, original = 1) {
  return api_emergency.previewFile(config.api, storeAuth.storeGetSelectedStaffId(true), emergency_id, file_id, original);
}
// 臨時タスク（エリアマネージャー用）
export function apiTimemngEmergencyCreateByAreaManager(emergency, attached_files, staff_id) {
  return api_emergency.create(config.api, staff_id, emergency, attached_files);
}
export function apiTimemngEmergencyAddShopByAreaManager(emergency_id, target_id, with_chatmessage, staff_id) {
  return api_emergency.addTarget(config.api, staff_id, emergency_id, target_id, with_chatmessage);
}
export function apiTimemngEmergencyAddTagByAreaManager(tag, staff_id) {
  return api_emergency.addTag(config.api, staff_id, tag);
}

// 個人タスク
export function apiTimemngPersonalTaskSearch(conditions) {
  return api_personal_task.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngPersonalTaskCreate(task) {
  return api_personal_task.create(config.api, storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngPersonalTaskUpdate(personal_task) {
  return api_personal_task.update(config.api, storeAuth.storeGetSelectedStaffId(), personal_task);
}
export function apiTimemngPersonalTaskAddPerson(personal_task_id, associate_ids, with_push) {
  return api_personal_task.addTarget(config.api, storeAuth.storeGetSelectedStaffId(), personal_task_id, associate_ids, with_push);
}
export function apiTimemngPersonalTaskRemovePerson(personal_task_id, associate_ids) {
  return api_personal_task.removeTarget(config.api, storeAuth.storeGetSelectedStaffId(), personal_task_id, associate_ids);
}
export function apiTimemngPersonalTaskAddAttachment(personal_task, attached_files) {
  return api_personal_task.addAttachment(config.api, storeAuth.storeGetSelectedStaffId(), personal_task, attached_files);
}
export function apiTimemngPersonalTaskRemoveAttachment(personal_task) {
  return api_personal_task.removeAttachment(config.api, storeAuth.storeGetSelectedStaffId(), personal_task);
}
export function apiTimemngPersonalTaskSaveAttachmentCaption(personal) {
  return api_personal_task.saveAttachmentCaption(config.api, storeAuth.storeGetSelectedStaffId(), personal);
}
export function apiTimemngPersonalTaskAddTag(personal_task_id, tags) {
  return api_personal_task.addTag(config.api, storeAuth.storeGetSelectedStaffId(), personal_task_id, tags);
}
export function apiTimemngPersonalTaskRemoveTag(personal_task_id, tags) {
  return api_personal_task.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), personal_task_id, tags);
}
export function apiTimemngPersonalTaskPreviewFile(personal_task_id, file_id, original = 1) {
  return api_personal_task.previewFile(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task_id, file_id, original);
}

// マニュアル
export  function apiTimemngManualSearch(conditions) {
  return api_manual.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngManualCreate(manual) {
  return api_manual.create(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualCreateRevision(manual) {
  return api_manual.createRevision(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualUpdate(manual) {
  return api_manual.update(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualDelete(manual) {
  return api_manual.delete(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualItemCreate(manual) {
  return api_manual.createItem(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualItemAdd(manual) {
  return api_manual.addItem(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualItemUpdate(manual) {
  return api_manual.updateItem(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualShopsAdd(manual) {
  return api_manual.addShops(config.api, storeAuth.storeGetSelectedStaffId(), manual);
}
export function apiTimemngManualAddTag(tag) {
  return api_manual.addTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngManualRemoveTag(tag) {
  return api_manual.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), tag);
}
export function apiTimemngManualPreviewFile(document_id, file_id, original = 1) {
  return api_manual.previewFile(config.api, storeAuth.storeGetSelectedStaffId(true), document_id, file_id, original);
}
export function apiTimemngManualSearchMyShop(conditions) {
  return api_manual.searchMyShop(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// ルーチンタスク
export async function apiTimemngRoutineGetTaskList(spec_date, staff_id, past_present_future, shop_id, task_type) {
  return api_routine.getTaskList(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), spec_date, past_present_future, shop_id, task_type);
}
export async function apiTimemngRoutineDoneTask(task, attached_files, staff_id) {
  return await api_routine.doneTask(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export async function apiTimemngRoutineDoneTaskSub(task, attached_files, staff_id) {
  return await api_routine.doneTaskSub(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task, attached_files);
}
export function apiTimemngRoutineTaskSaveShopDish(task, staff_id) {
  return api_routine.updateTaskShopDish(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(true), task);
}
export function apiTimemngRoutineTaskLogfileList(task) {
  return api_routine.taskLogfileList(config.api, storeAuth.storeGetSelectedStaffId(true), task);
}
export function apiTimemngRoutineTaskCommentList(task) {
  return api_routine.taskCommentList(config.api, storeAuth.storeGetSelectedStaffId(true), task);
}
export function apiTimemngRoutineTaskSaveComment(task) {
  return api_routine.upsertTaskComment(config.api, storeAuth.storeGetSelectedStaffId(true), task);
}
export function apiTimemngRoutineTaskCancel(task, staff_id) {
  return api_routine.cancelTask(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngRoutineTaskSubCancel(task, staff_id) {
  return api_routine.cancelTaskSub(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task);
}
export async function apiTimemngRoutineTaskGetLogs(task, page) {
  return await apiGetListLoop((_page) => {
    return api_routine.logsListTask(config.api, storeAuth.storeGetSelectedStaffId(), task, _page);
  }, page, 10)
}
export async function apiTimemngRoutineTaskSubGetLogs(task, page) {
  return await apiGetListLoop((_page) => {
    return api_routine.logsListTaskSub(config.api, storeAuth.storeGetSelectedStaffId(), task, _page);
  }, page, 10)
}
export function apiTimemngRoutineDailyReportList(report) {
  return api_routine.getRoutineDailyReportList(config.api, storeAuth.storeGetSelectedStaffId(), report);
}
export function apiTimemngRoutineGetDailyReports(shop_id, spec_date, request_day_count, withlogs) {
  return api_routine.getDailyReports(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, spec_date, request_day_count, withlogs);
}
export function apiTimemngRoutineTaskPreviewFile(routine_task_id, file_id, original = 1) {
  return api_routine.previewTaskFile(config.api, storeAuth.storeGetSelectedStaffId(true), routine_task_id, file_id, original);
}
export function apiTimemngRoutineTaskSubPreviewFile(routine_task_sub_id, file_id, original = 1) {
  return api_routine.previewTaskSubFile(config.api, storeAuth.storeGetSelectedStaffId(true), routine_task_sub_id, file_id, original);
}
export function apiTimemngRoutineTaskLogPreviewFile(routine_task_id, file_id, original = 1) {
  return api_routine.previewTaskLogFile(config.api, storeAuth.storeGetSelectedStaffId(true), routine_task_id, file_id, original);
}
export function apiTimemngRoutineTaskSubLogPreviewFile(routine_task_sub_id, file_id, original = 1) {
  return api_routine.previewTaskSubLogFile(config.api, storeAuth.storeGetSelectedStaffId(true), routine_task_sub_id, file_id, original);
}
export function apiTimemngRoutineSkipTask(task, staff_id) {
  return api_routine.skipTask(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngRoutineSkipTaskSub(task, staff_id) {
  return api_routine.skipTaskSub(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), task);
}
export function apiTimemngRoutineSkipTaskSubDish(dish, staff_id) {
  return api_routine.skipTaskSubDish(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), dish);
}

// ルーチン臨時タスク
export async function apiTimemngRoutineGetEmergencyToday(conditions) {
  return api_routine.getEmergencyToday(config.api, storeAuth.storeGetSelectedStaffId(true), conditions);
}
export async function apiTimemngRoutineGetEmergencyList(spec_date, staff_id, shop_id) {
  return api_routine.getEmergencyList(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), spec_date, shop_id);
}
export async function apiTimemngRoutineDoneEmergency(emergency, attached_files, staff_id) {
  return api_routine.doneEmergency(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), emergency, attached_files);
}
export function apiTimemngRoutineEmergencyGetLogs(emergency) {
  return api_routine.logsListEmergency(config.api, storeAuth.storeGetSelectedStaffId(), emergency);
}
export function apiTimemngRoutineEmergencyLogPreviewFile(emergency_id, file_id, original = 1) {
  return api_routine.previewEmergencyLogFile(config.api, storeAuth.storeGetSelectedStaffId(true), emergency_id, file_id, original);
}
export function apiTimemngRoutineEmergencyCommentList(task) {
  return api_routine.emergencyCommentList(config.api, storeAuth.storeGetSelectedStaffId(true), task);
}
export function apiTimemngRoutineEmergencySaveComment(task) {
  return api_routine.upsertEmergencyComment(config.api, storeAuth.storeGetSelectedStaffId(true), task);
}

// ルーチンチェックシート
export async function apiTimemngRoutineChecksheetDone(checksheet, staff_id) {
  return api_routine.doneChecksheet(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(), checksheet);
}
export async function apiTimemngRoutineChecksheetColumnAddAttachment(checksheet, staff_id, attached_files) {
  return api_routine.checksheetColumnAddAttachment(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(true), checksheet, attached_files);
}
export async function apiTimemngRoutineChecksheetColumnRemoveAttachment(checksheet, staff_id) {
  return api_routine.checksheetColumnRemoveAttachment(config.api, staff_id ?? storeAuth.storeGetSelectedStaffId(true), checksheet);
}
export async function apiTimemngRoutineChecksheetGetResults(checksheet) {
  return api_routine.getChecksheetResults(config.api, storeAuth.storeGetSelectedStaffId(), checksheet);
}
export async function apiTimemngRoutineChecksheetGetPeriodResults(checksheet, page = 1) {
  return api_routine.getChecksheetPeriodResults(config.api, storeAuth.storeGetSelectedStaffId(), checksheet, page);
}
export async function apiTimemngRoutineChecksheetExportPeriodResults(checksheet, export_format) {
  return api_routine.exportChecksheetPeriodResults(config.api, storeAuth.storeGetSelectedStaffId(true), checksheet, export_format);
}
export function apiTimemngRoutineChecksheetColumnPreviewFile(checked_column_id, file_id, original = 1) {
  return api_routine.previewChecksheetColumn(config.api, storeAuth.storeGetSelectedStaffId(true), checked_column_id, file_id, original);
}

// ルーチン個人タスク
export async function apiTimemngRoutinePersonalTaskGetToday(conditions) {
  return api_routine_personal_task.getToday(config.api, storeAuth.storeGetSelectedStaffId(true), conditions);
}
export async function apiTimemngRoutinePersonalTaskDone(personal_task, attached_files) {
  return api_routine_personal_task.done(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task, attached_files);
}
export async function apiTimemngRoutinePersonalTaskCancel(personal_task) {
  return api_routine_personal_task.cancel(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task);
}
export function apiTimemngRoutineGetLogs(personal_task) {
  return api_routine_personal_task.logsList(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task);
}
export function apiTimemngRoutinePersonalTaskLogPreviewFile(personal_task_log_id, file_id, original = 1) {
  return api_routine_personal_task.previewLogFile(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task_log_id, file_id, original);
}
export function apiTimemngRoutinePersonalTaskCommentList(personal_task) {
  return api_routine_personal_task.commentList(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task);
}
export function apiTimemngRoutinePersonalTaskSaveComment(personal_task) {
  return api_routine_personal_task.upsertComment(config.api, storeAuth.storeGetSelectedStaffId(true), personal_task);
}
export async function apiTimemngRoutinePersonalTaskStatusList(conditions) {
  return api_routine_personal_task.getStatusList(config.api, storeAuth.storeGetSelectedStaffId(true), conditions);
}

// 月別ルーチンプラン
export async function apiTimemngGetPlansPattern(spec_year, spec_month, shop_id) {
  return api_routine.getPlansPattern(config.api, storeAuth.storeGetSelectedStaffId(), spec_year, spec_month, shop_id);
}
export async function apiTimemngGetPlansEmergency(spec_year, spec_month, shop_id) {
  return api_routine.getPlansEmergency(config.api, storeAuth.storeGetSelectedStaffId(), spec_year, spec_month, shop_id);
}

// （本部用）ダッシュボード
export function apiTimemngHeadDashboardGetInfo(conditions) {
  return api_routine.getHeadDashborad(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
// （エリアマネージャー用）ダッシュボード
export function apiTimemngAreaDashboardGetInfo(spec_date, past_present_future, include_head, page = 1) {
  return api_routine.getAreaDashborad(config.api, storeAuth.storeGetSelectedStaffId(), spec_date, past_present_future, include_head, page);
}
export async function apiTimemngAreaDashboardGetDailyReports(spec_date, shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_routine.getAreaDashboradDailyReports(config.api, storeAuth.storeGetSelectedStaffId(), spec_date, shop_id, _page);
  }, page, 10)
}
export function apiTimemngAreaDashboardReportApprove(dashboard) {
  return api_routine.dashboradReportApprove(config.api, storeAuth.storeGetSelectedStaffId(), dashboard);
}
export function apiTimemngAreaDashboardEmergencyApprove(dashboard) {
  return api_routine.dashboradEmergencyApprove(config.api, storeAuth.storeGetSelectedStaffId(), dashboard);
}
export function apiTimemngAreaDashboardEmergencyRemand(dashboard) {
  return api_routine.dashboradEmergencyApprove(config.api, storeAuth.storeGetSelectedStaffId(), dashboard);
}
export async function apiTimemngAreaDashboardSearchChecksheetList(conditions) {
  return api_routine.dashboardChecksheeList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export async function apiTimemngHeadDashboardSearchChecksheetList(conditions) {
  return api_routine.dashboardHeadChecksheeList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// タスク実績一覧
export async function apiTimemngRoutineTaskStatusList(conditions) {
  let results = await api_routine.routineTaskStatusList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
  if (results && results.data) {
    results.data.map((v) => {
      if (v.task.start_time && v.task.start_time.startsWith('-')) {
        // マイナス対応
        v.task.start_time = ("0" + String(24 + Number(v.task.start_time.slice(0, 2)))).slice(-2) + v.task.start_time.slice(2, 4)
      }
      v.files.map((f) => (f.shop_id = v.task.shop_id))
    });
  }
  return results
}
export async function apiTimemngRoutineTaskSubList(conditions) {
  let results = await api_routine.routineTaskSubList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
  if (results) {
    results.map((v) =>
      v.logfiles.map((f) => (f.shop_id = v.task_sub.shop_id))
    );
  }
  return results
}
export async function apiTimemngRoutineTasksList(conditions) {
  return api_routine.routineTasksList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
// 臨時タスク実績一覧
export async function apiTimemngRoutineEmergencyStatusList(conditions) {
  let results = await api_routine.routineEmergencyStatusList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
  if (results && results.data) {
    results.data.map((v) =>
      v.logs.map((l) => { if (l.logfiles) l.logfiles.map((f) => (f.shop_id = v.emergencies.shop_id)) }
      ));
  }
  return results
}
export async function apiTimemngRoutineEmergencyTasksList(conditions) {
  return api_routine.routineEmergencyTasksList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// タグ
export async function apiTimemngTagsGetList(tag_type = 9, page) {
  return await apiGetListLoop((_page) => {
    return api_tags.getList(config.api, storeAuth.storeGetSelectedStaffId(), tag_type, _page);
  }, page, 10)
}
export function apiTimemngTagsCreate(tags) {
  return api_tags.create(config.api, storeAuth.storeGetSelectedStaffId(), tags);
}

// 翻訳
export function apiTimemngTranslateText(translate) {
  return api_translate.textTranslate(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}
export function apiTimemngTaskAddTranslation(translate) {
  return api_translate.addTranslationTask(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}
export function apiTimemngTaskSubAddTranslation(translate) {
  return api_translate.addTranslationTaskSub(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}
export function apiTimemngEmergencyAddTranslation(translate) {
  return api_translate.addTranslationEmergency(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}
export function apiTimemngChecksheetAddTranslation(translate) {
  return api_translate.addTranslationChecksheet(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}
export function apiTimemngChecksheetDetailAddTranslation(translate) {
  return api_translate.addTranslationChecksheetDetail(config.api, storeAuth.storeGetSelectedStaffId(), translate);
}

// ランキング
export function apiTimemngRankingMonthly(conditions) {
  return api_ranking.getMonthly(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngRankingWeekly(conditions) {
  return api_ranking.getWeekly(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngRankingMonthlyBrand(conditions) {
  return api_ranking.getMonthlyBrand(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngRankingWeeklyBrand(conditions) {
  return api_ranking.getWeeklyBrand(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngExcludeRankingList(conditions) {
  return api_ranking.getExCludeRankingList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngExcludeRanking(conditions) {
  return api_ranking.excludeRanking(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// ダッシュボード（ベータ）
export function apiTimemngDashboardSummaryInfo(conditions) {
  return api_dashboard.getSummaryInfo(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngDashboardShopsInfo(conditions) {
  return api_dashboard.getShopsInfo(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngDashboardShopDetail(conditions) {
  return api_dashboard.getShopDetail(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngDashboardEmergenciesInfo(conditions) {
  return api_dashboard.getEmergenciesInfo(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiTimemngDashboardEmergencyDetail(conditions) {
  return api_dashboard.getEmergencyDetail(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// ストリーミング動画取得URL
export function getTimemngStreamVideoApi(file_id, service_id, service_type) {
  if (isiOS()) {
    return getTimemngHlsStreamUrl(file_id, service_type)
  } else {
    return getTimemngStreamVideoUrl(file_id, service_id, service_type)
  }
}
export function getTimemngStreamVideoUrl(file_id, service_id, service_type) {
  let url = buildApiUrl(config.api, "stream/video")
  url += "?vid=" + file_id;
  url += "&sid=" + service_id;    // task_id | task_sub_id | emergency_id
  url += "&scd=" + service_type;  // task | tasksub | emergency
  url += "&uid=" + storeAuth.storeGetSelectedStaffId(true);
  url += "&token=" + storeAuth.storeGetIdToken();
  return url
}
export function getTimemngHlsStreamUrl(file_id, service_type) {
  let url = buildApiUrl(config.api, "hls/video")
  url += "/" + storeAuth.storeGetKeyAsSelectedCompany();
  url += "/" + service_type;  // task | tasksub | emergency
  url += "/" + file_id + "_hls.m3u8";
  url += "?token=" + storeAuth.storeGetIdToken();
  return url
}

export default {
  // お知らせ
  apiNotificationGetBarList,

  // タスク
  apiTimemngTaskGetList,
  apiTimemngTaskSearch,
  apiTimemngTaskCreate,
  apiTimemngTaskUpdate,
  apiTimemngTaskGetTagList,
  apiTimemngTaskCreateTag,
  apiTimemngTaskAddTag,
  apiTimemngTaskRemoveTag,
  apiTimemngTaskGetOptionList,
  apiTimemngTaskCreateOption,
  apiTimemngTaskAddOption,
  apiTimemngTaskRemoveOption,
  apiTimemngTaskAddAttachment,
  apiTimemngTaskRemoveAttachment,
  apiTimemngTaskPreviewFile,
  apiTimemngTaskSaveAttachmentCaption,
  apiTimemngTaskAddManual,
  apiTimemngTaskRemoveManual,
  apiTimemngTaskInUseSchedules,
  apiTimemngTaskExport,

  // サブタスク
  apiTimemngTaskSubGetList,
  apiTimemngTaskSubCreate,
  apiTimemngTaskSubUpdate,
  apiTimemngTaskSubAdd,
  apiTimemngTaskSubRemove,
  apiTimemngTaskSubGetOptionList,
  apiTimemngTaskSubAddOption,
  apiTimemngTaskSubRemoveOption,
  apiTimemngTaskSubAddAttachment,
  apiTimemngTaskSubRemoveAttachment,
  apiTimemngTaskSubPreviewFile,
  apiTimemngTaskSubSaveAttachmentCaption,
  apiTimemngTaskSubCreateDish,
  apiTimemngTaskSubUpdateDish,

  // タスク（My店舗）
  apiTimemngMyShopTaskSearch,
  apiTimemngMyShopTaskCreate,
  apiTimemngMyShopTaskUpdate,
  apiTimemngTemplateListMyShopSearch,
  // タスク（日報）
  apiTimemngTaskDailyReportList,
  apiTimemngTaskDailyReportCreate,
  apiTimemngTaskDailyReportUpdate,
  apiTimemngTaskDailyReportItemsSave,

  // タスクグループ
  apiTimemngTemplateGetList,
  apiTimemngTemplateTaskSearch,
  apiTimemngTemplateCreate,
  apiTimemngTemplateUpdate,
  apiTimemngTemplateAddTask,
  apiTimemngTemplateRemoveTask,
  // タスクグループ（My店舗用）
  apiTimemngTemplateGetListMyShop,
  apiTimemngTemplateCreateMyShop,

  // 業務スケジュール
  apiTimemngPatternGetList,
  apiTimemngPatternSearch,
  apiTimemngPatternCreate,
  apiTimemngPatternUpdate,
  apiTimemngPatternGetApplyValues,
  apiTimemngPatternAddApplyValue,
  apiTimemngPatternRemoveApplyValue,
  apiTimemngPatternAddTemplate,
  apiTimemngPatternRemoveTemplate,
  apiTimemngPatternAddShop,
  apiTimemngPatternRemoveShop,
  apiTimemngPatternGetExcludeTemplateList,
  apiTimemngPatternGetExcludeTaskList,
  apiTimemngPatternGetExcludeTemplateLogs,
  apiTimemngPatternGetExcludeTaskLogs,
  // 業務スケジュール（My店舗）
  apiTimemngPatternGetListMyShop,
  apiTimemngPatternSearchMyShop,
  apiTimemngPatternCreateMyShop,
  apiTimemngPatternUpdateMyShop,
  apiTimemngPatternExcludeTemplate,
  apiTimemngPatternExcludeTask,
  // 業務スケジュール（店舗カスタム）
  apiTimemngPatternCustomTaskHistory,
  apiTimemngPatternCustomTaskCreate,
  apiTimemngPatternCustomTaskUpdate,
  apiTimemngPatternCustomTaskApprove,
  apiTimemngPatternCustomTaskRemand,
  apiTimemngPatternCustomReset,
  // 新デザイン形式（テンプレート無しバージョン）
  apiTimemngPatternCreateNoTemplateVer,
  apiTimemngPatternCreateNoTemplateVerMyShop,

  // チェックシート
  apiTimemngChecksheetGetList,
  apiTimemngChecksheetGetListAll,
  apiTimemngChecksheetGetDetail,
  apiTimemngChecksheetCreate,
  apiTimemngChecksheetUpdate,
  apiTimemngChecksheetAddBrand,
  apiTimemngChecksheetRemoveBrand,
  apiTimemngChecksheetGetTagList,
  apiTimemngChecksheetCreateTag,
  apiTimemngChecksheetAddTag,
  apiTimemngChecksheetRemoveTag,
  apiTimemngChecksheetGetLogs,
  apiTimemngChecksheetAddDetailAttachment,
  apiTimemngChecksheetRemoveDetailAttachment,
  apiTimemngChecksheetDetailPreviewFile,
  // チェックシート(My店舗)
  apiTimemngChecksheetGetListMyShop,
  apiTimemngChecksheetCreateMyShop,

  // 臨時タスク
  apiTimemngEmergencyList,
  apiTimemngEmergencySearch,
  apiTimemngEmergencyCreate,
  apiTimemngEmergencyUpdate,
  apiTimemngEmergencyAddShop,
  apiTimemngEmergencyRemoveShop,
  apiTimemngEmergencyAddAttachment,
  apiTimemngEmergencyRemoveAttachment,
  apiTimemngEmergencySaveAttachmentCaption,
  apiTimemngEmergencyGetTagList,
  apiTimemngEmergencyCreateTag,
  apiTimemngEmergencyAddTag,
  apiTimemngEmergencyRemoveTag,
  apiTimemngEmergencyPreviewFile,
  // 臨時タスク（エリアマネージャー用）
  apiTimemngEmergencyCreateByAreaManager,
  apiTimemngEmergencyAddShopByAreaManager,
  apiTimemngEmergencyAddTagByAreaManager,

  // 個人タスク
  apiTimemngPersonalTaskSearch,
  apiTimemngPersonalTaskCreate,
  apiTimemngPersonalTaskUpdate,
  apiTimemngPersonalTaskAddPerson,
  apiTimemngPersonalTaskRemovePerson,
  apiTimemngPersonalTaskAddAttachment,
  apiTimemngPersonalTaskRemoveAttachment,
  apiTimemngPersonalTaskSaveAttachmentCaption,
  apiTimemngPersonalTaskAddTag,
  apiTimemngPersonalTaskRemoveTag,
  apiTimemngPersonalTaskPreviewFile,

  // マニュアル
  apiTimemngManualSearch,
  apiTimemngManualCreate,
  apiTimemngManualCreateRevision,
  apiTimemngManualUpdate,
  apiTimemngManualDelete,
  apiTimemngManualItemCreate,
  apiTimemngManualItemAdd,
  apiTimemngManualItemUpdate,
  apiTimemngManualShopsAdd,
  apiTimemngManualAddTag,
  apiTimemngManualRemoveTag,
  apiTimemngManualPreviewFile,
  apiTimemngManualSearchMyShop,

  // ルーチンタスク
  apiTimemngRoutineGetTaskList,
  apiTimemngRoutineDoneTask,
  apiTimemngRoutineDoneTaskSub,
  apiTimemngRoutineTaskSaveShopDish,
  apiTimemngRoutineTaskLogfileList,
  apiTimemngRoutineTaskCommentList,
  apiTimemngRoutineTaskSaveComment,
  apiTimemngRoutineTaskCancel,
  apiTimemngRoutineTaskSubCancel,
  apiTimemngRoutineTaskGetLogs,
  apiTimemngRoutineTaskSubGetLogs,
  apiTimemngRoutineDailyReportList,
  apiTimemngRoutineGetDailyReports,
  apiTimemngRoutineTaskPreviewFile,
  apiTimemngRoutineTaskSubPreviewFile,
  apiTimemngRoutineTaskLogPreviewFile,
  apiTimemngRoutineTaskSubLogPreviewFile,
  apiTimemngRoutineSkipTask,
  apiTimemngRoutineSkipTaskSub,
  apiTimemngRoutineSkipTaskSubDish,

  // ルーチン臨時タスク
  apiTimemngRoutineGetEmergencyToday,
  apiTimemngRoutineGetEmergencyList,
  apiTimemngRoutineDoneEmergency,
  apiTimemngRoutineEmergencyGetLogs,
  apiTimemngRoutineEmergencyLogPreviewFile,
  apiTimemngRoutineEmergencyCommentList,
  apiTimemngRoutineEmergencySaveComment,

  // ルーチンチェックシート
  apiTimemngRoutineChecksheetDone,
  apiTimemngRoutineChecksheetColumnAddAttachment,
  apiTimemngRoutineChecksheetColumnRemoveAttachment,
  apiTimemngRoutineChecksheetGetResults,
  apiTimemngRoutineChecksheetGetPeriodResults,
  apiTimemngRoutineChecksheetExportPeriodResults,

  // ルーチン個人タスク
  apiTimemngRoutinePersonalTaskGetToday,
  apiTimemngRoutinePersonalTaskDone,
  apiTimemngRoutinePersonalTaskCancel,
  apiTimemngRoutineGetLogs,
  apiTimemngRoutinePersonalTaskLogPreviewFile,
  apiTimemngRoutinePersonalTaskCommentList,
  apiTimemngRoutinePersonalTaskSaveComment,
  apiTimemngRoutinePersonalTaskStatusList,

  // 月別ルーチンプラン
  apiTimemngGetPlansPattern,
  apiTimemngGetPlansEmergency,

  // ダッシュボード
  apiTimemngHeadDashboardGetInfo,
  apiTimemngAreaDashboardGetInfo,
  apiTimemngAreaDashboardGetDailyReports,
  apiTimemngAreaDashboardReportApprove,
  apiTimemngAreaDashboardEmergencyApprove,
  apiTimemngAreaDashboardEmergencyRemand,
  apiTimemngAreaDashboardSearchChecksheetList,
  apiTimemngHeadDashboardSearchChecksheetList,

  // タスク実績一覧
  apiTimemngRoutineTaskStatusList,
  apiTimemngRoutineTaskSubList,
  apiTimemngRoutineTasksList,
  // 臨時タスク実績一覧
  apiTimemngRoutineEmergencyStatusList,
  apiTimemngRoutineEmergencyTasksList,

  // タグ
  apiTimemngTagsGetList,
  apiTimemngTagsCreate,

  // 翻訳
  apiTimemngTranslateText,
  apiTimemngTaskAddTranslation,
  apiTimemngTaskSubAddTranslation,
  apiTimemngEmergencyAddTranslation,
  apiTimemngChecksheetAddTranslation,
  apiTimemngChecksheetDetailAddTranslation,

  // ランキング
  apiTimemngRankingMonthly,
  apiTimemngRankingWeekly,
  apiTimemngRankingMonthlyBrand,
  apiTimemngRankingWeeklyBrand,

  // ダッシュボード（ベータ）
  apiTimemngDashboardSummaryInfo,
  apiTimemngDashboardShopsInfo,
  apiTimemngDashboardShopDetail,
  apiTimemngDashboardEmergenciesInfo,
  apiTimemngDashboardEmergencyDetail,

  // ストリーミング動画取得URL
  getTimemngStreamVideoApi,
  getTimemngStreamVideoUrl,
  getTimemngHlsStreamUrl,
}