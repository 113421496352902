import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/create",
            query: { staff_id: staff_id, manual: manual },
        })
    },

    // 更新
    createRevision(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/create/revision",
            query: { staff_id: staff_id, manual: manual },
        })
    },
    update(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/update",
            query: { staff_id: staff_id, manual: manual },
        })
    },
    delete(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/delete",
            query: { staff_id: staff_id, manual: manual },
        })
    },

    // 行項目
    createItem(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/item/create",
            query: { staff_id: staff_id, manual: manual },
        })
    },
    addItem(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/event/add/item",
            query: { staff_id: staff_id, manual: manual },
        })
    },
    updateItem(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/event/update/item",
            query: { staff_id: staff_id, manual: manual },
        })
    },

    // 適用店舗
    addShops(apiconf, staff_id, manual) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/event/add/shops",
            query: { staff_id: staff_id, manual: manual },
        })
    },

    // ファイル
    previewFile(apiconf, staff_id, document_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/manual/file",
            query: {
                staff_id: staff_id,
                document_id: document_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // タグ
    addTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/event/add/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    removeTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "manual/event/remove/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },

    // （My店舗）一覧取得
    searchMyShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/manual/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}