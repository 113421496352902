/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import pinia from '../store/index'
import router from '../router'
import HighchartsVue from 'highcharts-vue'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

export function registerPlugins (app) {
  loadFonts()
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(HighchartsVue)
    .use(PrimeVue, {
      theme: {
        preset: Aura
      }
    })
}
